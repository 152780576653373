import { GOADate } from 'utils/date/GOADate';

export type DeviationMessage = {
  id: number;
  situationId: string;
  status: string;
  affectedProductLines: string[];
  affectedTrainServices: any[];
  stationFrom: string;
  stationTo: string;
  fromDateTime: GOADate;
  untilDateTime: GOADate;
  isPlanned: boolean;
  hasReplacements: boolean;
  withDailyTimeSlot: boolean;
  weekDays: {
    no: string[];
    en: string[];
  };
  messageAlert: {
    id: number;
    deviationId: number;
    no: string;
    en: string;
    deviation: any;
  };
  messageDescription: {
    id: number;
    deviationId: number;
    no: string;
    en: string;
    deviation: any;
  };
  updatedTime: GOADate;
};

export type ModifiedDeviationMessage = {
  key: string;
  state: string;
  value: DeviationMessage;
};

export const SET_CRITICAL_DEVIATIONS = 'SET_CRITICAL_DEVIATIONS';
export type DeviationsList = DeviationMessage[];

type SetCriticalDeviations = {
  type: typeof SET_CRITICAL_DEVIATIONS;
  payload: DeviationsList;
};

export type DeviationsActionTypes = SetCriticalDeviations | DeviationsList;
