import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { GOADate } from 'utils/date/GOADate';
import {
  TxtDefaultRegularDarkResp,
  TxtLargeBoldDarkResp,
  HiddenSpan,
  TxtSmallMediumDarkResp,
} from 'elements/new-design/Typography';
import { MarginTop, MarginBottom } from 'elements/distance/Margins';
import { TxtLinkDefault } from 'elements/links/Links';
import { Config } from 'utils/Config';

type CriticalDeviationProps = {
  deviation: any;
  altLocations: any;
};
type StyleProps = {
  isCritical: boolean;
};

const DeviationContainer = styled.div`
  ${(_: StyleProps) => ''}
  padding: 0.8rem 1.6rem;
  margin-bottom: 2.4rem;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'title' 'body';
  grid-gap: 0.8rem;
  box-shadow: ${(props) => props.theme.newcolors.borderBoxshadow};
`;

const Body = styled.div`
  grid-area: body;
  display: grid;
  grid-gap: 1.6rem;
`;

const formatOperatingTime = (
  deviation: any,
  translate: (key: string) => string,
  getI18nDate: (date: GOADate) => GOADate,
) => {
  const operatingDate =
    typeof deviation.affectedTrainServices[0].operatingDate === 'string'
      ? getI18nDate(new GOADate(deviation.affectedTrainServices[0].operatingDate))
      : deviation.affectedTrainServices[0].operatingDate;
  const isToday = operatingDate.isSameDay(new GOADate());
  const isTomorrow = operatingDate.isSameDay(new GOADate().addDays(1));

  return `${
    isToday
      ? translate('TODAY_FROM').toLowerCase()
      : isTomorrow
      ? translate('TOMORROW_FROM').toLowerCase()
      : operatingDate.formatDayMonthYearNorwegian()
  } `;
};

const formatUpdatedTime = (
  deviation: any,
  translate: (key: string) => string,
  getI18nDate: (date: GOADate) => GOADate,
) => {
  const updatedDate = getI18nDate(deviation.updatedTime);

  const isToday = updatedDate.isSameDay(new GOADate());
  const isYsterday = updatedDate.isSameDay(new GOADate().subDays(1));

  return `${
    isToday
      ? translate('TODAY').toLowerCase()
      : isYsterday
      ? translate('YESTERDAY').toLowerCase()
      : updatedDate.formatDayMonthYearNorwegian()
  } `;
};

const formatDescription = (description: string) => description.split('\n');

// When a deviation is updated through signalR, all types of dates on other deviations changes from string to GOADate.
// Similar fix has been applied above to "formatOperatingTime"
const formatStationTime = (affectedTrain: any, altLocations: any) => {
  const stationOrigin = altLocations.find((item) => item.locationId === affectedTrain.origin)?.locationName;
  const stationDestination = altLocations.find((item) => item.locationId === affectedTrain.destination)?.locationName;

  const departureTime =
    typeof affectedTrain?.originDepartureTime === 'string'
      ? new GOADate(affectedTrain?.originDepartureTime).formatTime()
      : affectedTrain?.originDepartureTime.formatTime();
  const arrivalTime =
    typeof affectedTrain?.destinationArrivalTime === 'string'
      ? new GOADate(affectedTrain?.destinationArrivalTime).formatTime()
      : affectedTrain?.destinationArrivalTime.formatTime();

  return `${stationOrigin} ${departureTime} - ${stationDestination} ${arrivalTime}`;
};

export const CriticalDeviationAffectedService = ({ deviation, altLocations }: CriticalDeviationProps): JSX.Element => {
  const { currentLanguage, translate, getI18nDate } = useI18n();
  const affectedTrain = deviation.affectedTrainServices[0];
  const opsTrafficUrl = Config().opsTrainCardUrl;

  return (
    <DeviationContainer isCritical={deviation.messageAlert}>
      <Body>
        <div>
          <MarginBottom margin={0.8}>
            <p>
              <HiddenSpan>{formatOperatingTime(deviation, translate, getI18nDate)}</HiddenSpan>
              <TxtLargeBoldDarkResp>{`${translate('TRAIN')} ${affectedTrain?.trainNumber} `}</TxtLargeBoldDarkResp>
              <TxtLargeBoldDarkResp aria-hidden={true}>
                {formatOperatingTime(deviation, translate, getI18nDate)}
              </TxtLargeBoldDarkResp>
              <TxtLargeBoldDarkResp>{formatStationTime(affectedTrain, altLocations)}</TxtLargeBoldDarkResp>
            </p>
          </MarginBottom>
        </div>

        <p>
          {formatDescription(
            currentLanguage === 'no' ? deviation.messageDescription.no : deviation.messageDescription.en,
          ).map((str, index) => (
            <TxtDefaultRegularDarkResp key={index}>
              {str}
              <br />
            </TxtDefaultRegularDarkResp>
          ))}
        </p>
        <MarginTop margin={0.8}>
          <TxtLinkDefault href={`${opsTrafficUrl}${affectedTrain.serviceId}`} target="_blank" rel="noreferrer">
            {translate('MORE_INFO')}
          </TxtLinkDefault>
        </MarginTop>

        <TxtSmallMediumDarkResp>{`${translate('LAST_UPDATED_AT')} ${formatUpdatedTime(
          deviation,
          translate,
          getI18nDate,
        )} ${deviation.fromDateTime.formatTime()}`}</TxtSmallMediumDarkResp>
      </Body>
    </DeviationContainer>
  );
};
