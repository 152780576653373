import React from 'react';
import styled from 'styled-components';
import { DeviationMessage } from 'types/DeviationTypes';
import { GeneralDeviation } from 'components/new-design/deviations/GeneralDeviation';
import { CriticalDeviationAffectedService } from 'components/new-design/deviations/CriticalDeviationAffectedService';
import { TxtXLargeMediumDarkResp } from 'elements/new-design/Typography';
import { MarginBottom, MarginRight, MarginTop } from 'elements/distance/Margins';
import { FlexRow } from 'elements/containers/Containers';

type DeviationsSectionProps = {
  title: string;
  deviations: DeviationMessage[];
  icon?: JSX.Element;
  altLocations: any;
};

const DeviationsList = styled.ul`
  list-style: none;
`;

const sortByOriginDepartureTime = (deviationA: any, deviationB: any) => {
  const depTimeA = deviationA.affectedTrainServices[0]?.originDepartureTime;
  const depTimeB = deviationB.affectedTrainServices[0]?.originDepartureTime;

  if (depTimeA < depTimeB) {
    return -1;
  } else if (depTimeA > depTimeB) {
    return 1;
  }

  return 0;
};

export const DeviationsSection = ({ title, deviations, icon, altLocations }: DeviationsSectionProps): JSX.Element => {
  return (
    <li>
      <MarginTop margin={5.6}>
        <MarginBottom margin={3.2}>
          <FlexRow>
            {icon ? <MarginRight margin={0.8}>{icon}</MarginRight> : null}

            <h2>
              <TxtXLargeMediumDarkResp>{title}</TxtXLargeMediumDarkResp>
            </h2>
          </FlexRow>
        </MarginBottom>

        <DeviationsList>
          {deviations
            .sort((deviationA, DeviationB) => sortByOriginDepartureTime(deviationA, DeviationB))
            .map((dev: DeviationMessage, idx: number) => (
              <li key={idx}>
                {dev.affectedTrainServices[0] ? (
                  <CriticalDeviationAffectedService deviation={dev} altLocations={altLocations} />
                ) : (
                  <GeneralDeviation deviation={dev} />
                )}
              </li>
            ))}
        </DeviationsList>
      </MarginTop>
    </li>
  );
};
