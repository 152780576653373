import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { useApi } from 'hooks/use-api/useApi';
import { useSelector } from 'react-redux';
import { AppState } from 'store/index';
import { Constants } from 'utils/Constants';
import { BreadcrumbsPage } from 'components/new-design/layouts/BreadcrumbsPage';
import { SmallContainer, SmallTopContainer } from 'components/new-design/layouts/LayoutContainers';
import { IsTrainOnTimeLink } from 'elements/new-design/links/IsTrainOnTime';
import { SectionStationSearch } from 'components/section-station-search/SectionStationSearch';
import { DeviationsSection } from './DeviationsSection';
import { WarningIconWarning, InfoIconWarning, InfoIconInfo } from 'elements/new-design/icons/SymbolIcons';
import { SectionMessage } from 'elements/new-design/messages/SectionMessage';
import { ContainerBorderBoxShadow } from 'elements/new-design/Containers';
import { MarginBottom, MarginTop } from 'elements/distance/Margins';
import { CustomerServiceContactInfo } from 'elements/new-design/contacts/CustomerServiceContactInfo';
import { handleDateConversion } from 'utils/Helpers';

type DeviationsPageProps = {
  title: string;
};

const DeviationsList = styled.ul`
  list-style: none;
`;

const SearchContainer = styled(ContainerBorderBoxShadow)`
  padding: 2.4rem;
`;

const DeviationsState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR}`;

export const DeviationsPage = ({ title }: DeviationsPageProps): JSX.Element => {
  const criticalDeviations = useSelector((state: AppState) => state.deviations);
  const { translate } = useI18n();
  const { API_CALLS, requestGoOps } = useApi();
  const [plannedDeviations, setPlannedDeviations] = useState(null);
  const [hasDeviations, setHasDeviations] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [state, setState] = useState<typeof DeviationsState>(Constants.LOADING);
  const [altLocations, setAltLocations] = useState([]);

  const fetchDeviations = async () => {
    //use critical deviations from signalR connection saved in state to get real time updates
    try {
      const { data } = await requestGoOps(API_CALLS.GET_ALL_GOOPS_DEVIATIONS);
      const { data: altLocationsData } = await requestGoOps(API_CALLS.GET_RTV_STATIONS);
      setAltLocations(altLocationsData.result);
      handleDateConversion(data.result.critical);
      handleDateConversion(data.result.futurePlanned);
      handleDateConversion(data.result.plannedOngoing);
      setPlannedDeviations(data.result);
      setState(Constants.IDLE);
    } catch (error) {
      setState(Constants.ERROR);
    }
  };

  useEffect(() => {
    fetchDeviations();
  }, []);

  useEffect(() => {
    if (plannedDeviations) {
      setHasDeviations(
        Boolean(criticalDeviations.length) ||
          Boolean(plannedDeviations.plannedOngoing?.length) ||
          Boolean(plannedDeviations.futurePlanned?.length),
      );
    }
  }, [criticalDeviations, plannedDeviations]);

  return (
    <BreadcrumbsPage title={title} loading={false}>
      <SmallContainer>
        <IsTrainOnTimeLink type="button" expanded={expanded} onButtonClick={() => setExpanded(!expanded)} />
        {expanded && (
          <SmallTopContainer>
            <SearchContainer>
              <SectionStationSearch
                small="100%"
                medium="100%"
                large="100%"
                displaySwap={false}
                displaySubmit={true}
                redirectUrl={Constants.ROUTE_JOURNEY}
              />
            </SearchContainer>
          </SmallTopContainer>
        )}

        {state === Constants.ERROR && (
          <MarginTop margin={3.6}>
            <SectionMessage
              type={Constants.ERROR_MESSAGE}
              title={translate('GENERAL_ERROR_HEADLINE')}
              text={translate('REQUEST_DEVIATIONS_ERROR')}
            >
              <MarginBottom margin={1.6} />
              <CustomerServiceContactInfo />
            </SectionMessage>
          </MarginTop>
        )}
        {state === Constants.IDLE && (
          <>
            {!hasDeviations && (
              <MarginTop margin={3.6}>
                <SectionMessage
                  type={Constants.INFO_MESSAGE}
                  title={translate('NO_DEVIATIONS_HEADLINE')}
                  text={translate('NO_DEVIATIONS_TEXT')}
                />
              </MarginTop>
            )}
            {hasDeviations && (
              <DeviationsList>
                {Boolean(criticalDeviations.length) && (
                  <DeviationsSection
                    title={translate('CRITICAL_DEVIATIONS')}
                    deviations={criticalDeviations}
                    icon={<WarningIconWarning width={4.5} height={4.5} />}
                    altLocations={altLocations}
                  />
                )}
                {Boolean(plannedDeviations.plannedOngoing?.length) && (
                  <DeviationsSection
                    title={translate('ON_GOING_WORK')}
                    deviations={plannedDeviations.plannedOngoing}
                    icon={<InfoIconWarning width={4.5} height={4.5} />}
                    altLocations={altLocations}
                  />
                )}
                {Boolean(plannedDeviations.futurePlanned?.length) && (
                  <DeviationsSection
                    title={translate('PLANNED_WORK')}
                    deviations={plannedDeviations.futurePlanned}
                    icon={<InfoIconInfo width={4.5} height={4.5} />}
                    altLocations={altLocations}
                  />
                )}
              </DeviationsList>
            )}
          </>
        )}
      </SmallContainer>
    </BreadcrumbsPage>
  );
};
