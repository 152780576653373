const markDefs = `
  markDefs[]{
    ...,
    _type == "internalLink" => {
      "slug": @.page->slug,
      "parent": @.page->link->,
    },
    _type == "asset" => {
      ...,
      "fileURL": @.linkedAsset->asset.asset->url
    },
  }`;

export const campaignQuery = `
 referencedCampaign -> {
      apiId,
      callToActionText,
      callToActionTitle,
      daysOfValidity,
      detailedText,
      saleStartAt,
      endAtUtc,
      launchAtUtc,
      price,
      "image": image{
        "url": asset->url,
        "alt": alt,
      },
      reportRef,
      ticketInfoText,
      title,
      "bgColor": primaryColor->color.hex,
      "secondaryColor": secondaryColor->color.hex,
    },
  `;

const linkInternal = `
  coalesce(articleLink.internal->{slug{current}, "parentLink": link->slug{current}}, link.linkInternal->{slug{current}, "parentLink": link->slug{current}})
`;

const articleBoxQuery = `
  referencedArticleBox->{
    "text": text,
    "heading": heading,
    "linkText": linkText,
    "linkInternal": ${linkInternal},
    "linkExternal": coalesce(articleLink.external, link.linkExternal),
    "image": image{
      "url": asset->url,
      "alt": alt,
    },
  },
`;

const infoBoxQuery = `
  referencedInfoBox->{
    "heading": heading,
    "bgColor": bgColor->color.hex,
    "image": image{
      "url": asset->url,
      "alt": alt,
    },
    "body": infoBoxText[]{
      ...,
      ${markDefs}
    },
  },
`;

const twoColumnsQuery = `
  twoColumnSections[]->{
    ...,
    "bgColor": bgColor->color.hex,
    "linkInternal": ${linkInternal},
    "linkExternal": coalesce(articleLink.external, link.linkExternal),
    "image": image{
      "url": asset->url,
      "alt": alt,
    },
    infoBoxText[]{
      ...,
      ${markDefs}
    },
  },
`;

const threeColumnsQuery = `
  threeColumnSections[]{
    "linkInternal": ${linkInternal},
    "linkExternal": coalesce(articleLink.external, link.linkExternal),
    "title": title,
    "text": text,
    "image": image{
      "url": asset->url,
      "alt": alt,
    },
  },
`;

const stationSearch = `
    "fromStation": referencedFromStation->{
      name,
      id,
    },
    "toStation": referencedToStation->{
      name,
      id,
    },
`;

const imageQuery = `
  "url": asset->url,
  "alt": alt,
  "caption": caption,
  "copyright": copyright,
  "copyrightColor": copyrightColor->color.hex,
  "displayOnPage": displayOnPage,
`;

const videoQuery = `
  "video": {
    "url": asset.asset->url,
    "alt": alt,
    "caption": caption,
  },
`;

const youtubeQuery = `
  "youtube": {
    "url": url,
  },
`;

const mainImageQuery = `
  "mainImage": mainImage{
    ${imageQuery}
  },
`;

export const pageQuery = `
        ...,
        ${mainImageQuery}
        ${campaignQuery}
        "parentLink": link->slug{current},
        body[]{
          ...,
          "url": image.asset->url,
          "copyrightColor": copyrightColor->color.hex,
          ${twoColumnsQuery}
          ${threeColumnsQuery}
          ${stationSearch}
          ${videoQuery}
          ${youtubeQuery}
          ${campaignQuery}
          ${infoBoxQuery}
          ${articleBoxQuery}
          ${markDefs}
        },
        'locales': *[_type == "translation.metadata" && references(^._id)].translations[].value->{
          'locale': language, 
          'slug': slug.current 
        }, 
      }`;

export const partnerPageQuery = `
        ...,
        'notLoggedIn': notLoggedIn{
          ...,
          body[]{
            ...,
          "url": image.asset->url,
          "copyrightColor": copyrightColor->color.hex,
          ${twoColumnsQuery}
          ${threeColumnsQuery}
          ${stationSearch}
          ${videoQuery}
          ${youtubeQuery}
          ${campaignQuery}
          ${infoBoxQuery}
          ${articleBoxQuery}
          ${markDefs}
          },
        },
        'loggedIn': loggedIn{...},
        'locales': *[_type == "translation.metadata" && references(^._id)].translations[].value->{
          'locale': language, 
          'slug': slug.current 
        }, 
      }`;

export const landingPageQuery = `
  title,
  language,
  slug,
  "tag": tag->.name,
  apiId,
  "subPages":coalesce(
    articlePage[]->{
      "id": _id,
      "heading": title, 
      "text": preamble, 
      "image": mainImage{${imageQuery}},
      publishAt,
      unpublishAt,
      "linkText": title,
      "linkInternal": {
        "parentLink": link->slug{current},
        slug,
      },
    }, 
    standardPage[]->{
      title,
      slug,
      externalLink,
      apiId,
      "assetLink": asset.asset->url,
    },
  ),
  'locales': *[_type == "translation.metadata" && references(^._id)].translations[].value->{
    'locale': language, 
    'slug': slug.current 
  }
}`;
